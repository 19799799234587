<template>
    <Overview 
        moduleName="Bronwebsite"
        moduleNamePlural="Bronwebsites"
        :sort="'name'"
        :itemService=itemService
        :itemFormRef=itemFormRef
    >
        <template #columns>
            <Column field="name" header="Naam" :sortable="true"></Column>
            <Column field="code" header="Code" :sortable="true" style="width: 40%"></Column>
            <Column field="price" header="Prijs" :sortable="true" >
                <template #body="slotProps">
                    <span v-if='slotProps.data.price'>{{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(slotProps.data.price)}} </span>
                </template>
            </Column>
            <Column field="id" header="Niet doorbelasten">
                <template #body="slotProps">
                    <i v-if='!slotProps.data.source_charge_contact' class="fa fa-check text-success"></i>
                </template>
            </Column>
        </template>

        <template #form>
            <SourceForm ref="ItemForm" :itemData="item" />
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column'
import SourceForm from '@/components/admin/SourceForm.vue'
import sourceService from '@/services/admin/SourceService'

export default {
    components: {
        Overview,
        Column,
        SourceForm
    },
    data() {
        return {
            item: {},
            itemService: sourceService,
            itemFormRef: null
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>