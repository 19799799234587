import {ApiService} from '@/services/ApiService'

const URL = '/api/admin/source'

class SourceService extends ApiService {
  constructor(){
    super()
  }
  getUrl(){
      return URL
  }
}

export default new SourceService()