<template>
    <div class="row">
        <label for="name" class="col-sm-2 col-form-label">Naam</label>
        <div class="col-sm-8">
            <input v-model="source.name" name="name" class="form-control form-control-sm"/>
        </div>
    </div>
    <div class="row">
        <label for="code" class="col-sm-2 col-form-label">Code</label>
        <div class="col-sm-8">
            <input v-model="source.code" name="code" class="form-control form-control-sm"/>
        </div>
    </div>
    <div class="row">
        <label for="url" class="col-sm-2 col-form-label">Url</label>
        <div class="col-sm-8">
            <input v-model="source.url" name="url" class="form-control form-control-sm"/>
        </div>
    </div>
    <div class="row">
        <label for="price" class="col-sm-2 col-form-label">Prijs</label>
        <div class="col-sm-8">
            <div class="input-group">
                <span class="input-group-text">€</span>
                <input v-model="source.price" name="price" class="form-control form-control-sm"/>
            </div>
        </div>
    </div>
    <div class="mt-1">
        <input type="checkbox" id="not-extra" class="form-check-input d-inline-block" v-model="source.source_charge_contact" :true-value="0" :false-value="1"/>
        <label for="not-extra" class="ms-2 d-inline"> Niet aan cliënt doorbelasten </label>
    </div>
    <div class="mt-1">
        <label for="user" class="col-sm-2 col-form-label">Email lead naar:</label><br>
        <template v-for="user in users" v-bind:key="user.id">
            <input type="checkbox" name="user" v-model="source.users" :value="user.id" :id="'leads_checkbox_' + user.id" class="form-check-input d-inline-block" :true-value="1" :false-value="0"/>
            <label :for="'leads_checkbox_' + user.id" class="ms-2 d-inline"> {{getName(user)}} ({{user.email}})</label> <br>
        </template>
    </div>
</template>
                    


<script>
  import userservice from '@/services/admin/UserService'
    export default {
        name: 'SourceForm',
        props: ['itemData'],
        data() {
            return {
                source: {
                    users: []
                },
                submitted: false,
                loading: false,
                invalid: {},
                users: []
            }
        },
        watch: {
            itemData: function (val) {
                this.source = val    
            },
        },
        methods: {
            validate(){
                this.invalid = {};
                let valid = true;

                // let required = document.querySelectorAll('[required]');
                return valid;
            },
            getData() {
                return this.source;
            },
            setData(data) {
                this.source = data
            },
            reset() {
                this.source = {
                    'code': '',
                    'name': '',
                    'price': '',
                    'source_charge_contact': true
                }
                
                this.loading = false
                this.invalid = {}
            },
            getUsers() {
                userservice.index().then(response => {
                this.users = response.data
                }).catch(error => {
                this.$toast.error('Failed to retreive data')
                })
            },
        },
        mounted() {
            this.reset()
            this.getUsers()
        }
    }
</script>